<template>
  <div class="my6 md:my0">
    <h3 class="text-base fw600 md:block n-text-heading">
      RECENZE ZÁKAZNÍKŮ
    </h3>
    <div class="mt2 max-w-sm rounded-md bg-gray-1 p-4">
      <div class="space-y-4">
        <div class="border-b border-gray-200 pb-3">
          <div class="mb-2 flex items-center justify-between">
            <span class="text-gray-800 font-medium">Tomáš V.</span>
            <div class="flex gap-1">
              <Rating :rating="4.5" class="text-xs -mt0.5" />
            </div>
          </div>
          <ul class="text-sm text-green-600 space-y-1">
            <li>• Rychlé doručení</li>
            <li>• Přehledný web</li>
            <li>• Výborná zákaznická podpora</li>
          </ul>
        </div>

        <div>
          <div class="mb-2 flex items-center justify-between">
            <span class="text-gray-800 font-medium">Eva M.</span>
            <div class="flex gap-1">
              <Rating :rating="5" class="text-xs -mt0.5" />
            </div>
          </div>
          <ul class="text-sm text-green-600 space-y-1">
            <li>• Široká nabídka produktů</li>
            <li>• Možnost platby kartou i převodem</li>
            <li>• Skvělé balení objednávky</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
